import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { StateService } from 'angular-ui-router';
import { Observable, Subscription, from } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AutocompleteOrgsComponent } from 'webapp/app/shared/autocomplete-components/organizations/autocomplete-orgs.component';
import {
	GaContentDialogComponent,
	GaContentDialogModel,
} from 'webapp/app/shared/ga-dialog/ga-content-dialog.component';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { MixPanelService } from 'webapp/app/shared/services/mixpanel.service';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { TariffListService } from './tariff-list.service';
import { DataTableList } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import * as moment from 'moment';
import { ActionButton } from 'webapp/app/shared/models/selector.model';
import { UserService } from 'webapp/app/shared/services/user.service';

@Component({
	selector: 'tariff-list',
	templateUrl: './tariff-list.component.html',
	styleUrls: ['./tariff-list.component.scss'],
})
export class TariffListComponent implements OnInit, OnDestroy {
	organizationId: number | undefined = undefined;
	subscriptions: Array<Subscription> = [];
	actionButtons: ActionButton[] = [];
	displayElPrivate$: Observable<any> = new Observable();
	displayElPublic$: Observable<any> = new Observable();
	isOrgAdmin = false;
	isAdmin = false;
	dialogRef!: MatDialogRef<any>;
	tariffs: any[] = [];
	subGroups: any[] = [];
	@ViewChild(AutocompleteOrgsComponent)
		organizationsComponent!: AutocompleteOrgsComponent;

	constructor(
		@Inject(NG_ROUTER) private $state: StateService,
		@Inject(TranslocoService) private i18n: TranslocoService,
		public dialog: MatDialog,
		private mixpanel: MixPanelService,
		private notification: NotificationService,
		private tariffListService: TariffListService,
		private userService: UserService
	) {}

	ngOnInit(): void {
		this.setUserAdmin();
		this.loadSubGroups();
		this.listTariffPublicTable();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
	}

	setUserAdmin() {
		const subscription = from(this.userService.getCurrentUser())
			.pipe(
				tap((user) => {
					this.isAdmin = user.isAdmin;
					this.initActionButtons();
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	mixPanelEvent(type) {
		this.mixpanel.mixPanelEvent({ type, object: {} });
	}

	initActionButtons() {
		const translateCodes = [
			'regulated-list.type-distr',
			'regulated-list.type-distr-small',
			'regulated-list.type-priv',
			'regulated-list.type-priv-small',
		];
		this.subscriptions.push(
			this.i18n
				.selectTranslate(translateCodes)
				.pipe(
					tap((labels) => {
						this.actionButtons = [
							{
								title: labels[2],
								subtitle: labels[3],
								actionName: 'privateTariff',
							},
						];
						if (this.isAdmin) {
							this.actionButtons.unshift({
								title: labels[0],
								subtitle: labels[1],
								actionName: 'publicTariff',
							});
						}
					})
				)
				.subscribe()
		);
	}

	newAction(event) {
		const route = event.type ? 'main.tariffDetails' : 'main.tariffNew';
		const params: any = {
			id: event?.row?.tariff || null,
			instanceId: event?.row?.id || null,
			tab: this.$state.params.tab,
			clone: event?.type == 'clone',
			'#': event?.type == 'edit' && event?.id == event?.row?.id ? 'edit-cycle' : event?.type || event.actionName,
			organizationId: this.organizationId,
		};
		if (!event.type) this.mixPanelEvent(`click_button_tariff_${event.actionName.toLowerCase()}_create`);
		sessionStorage.setItem('selectedRow', JSON.stringify(event?.row));
		this.stateGo(route, params);
	}

	newCicle(event) {
		const route = 'main.tariffCicle';
		const params: any = {
			id: event.id,
			instanceId: event?.row?.id || null,
			tab: this.$state.params.tab,
			'#': event.type,
		};
		this.stateGo(route, params);
	}

	stateGo(route, params) {
		this.$state.transitionTo(route, params);
	}

	orgSelected(event: { id: number; name: string }) {
		if (!event?.id || event?.id === this.organizationId) return;
		this.organizationId = event?.id;
		this.isOrgAdmin = this.organizationsComponent.isOrgAdmin || this.organizationsComponent.currentUser.isAdmin;
		this.listTariffPrivateTable();
	}

	loadSubGroups() {
		const subscription = this.tariffListService
			.getTariffSubGroups()
			.pipe(
				tap((response) => {
					this.subGroups = response.subGroups;
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	listTariffPrivateTable() {
		this.displayElPrivate$ = this.tariffListService
			.getTariffs({
				organizationId: this.organizationId,
				subType: 'regulated',
			})
			.pipe(
				map((data) => data.tariffs.sort(this.sortTariffsByPeriod)),
				tap((tariffs) => this.mapTariffFields(tariffs)),
				map((tariffs) => this.tariffToList(tariffs))
			);
	}

	listTariffPublicTable() {
		this.displayElPublic$ = this.tariffListService
			.getTariffs({
				onlyPublic: true,
			})
			.pipe(
				map((data) => data.tariffs.sort(this.sortTariffsByPeriod)),
				tap((tariffs) => this.mapTariffFields(tariffs)),
				map((tariffs) => this.tariffToList(tariffs))
			);
	}

	mapTariffFields(tariffs) {
		return tariffs
			.map((tariff) => {
				tariff.private = tariff.private == 'true';
				return tariff;
			})
			.map((tariff) => {
				tariff.subGroup = this.subGroups.find((sub) => sub.id == tariff.subGroup);
				return tariff;
			});
	}

	tariffToList(tariffs) {
		const element: DataTableList = {} as DataTableList;
		element.data = this.tariffListService.renderListToTable(tariffs);
		element.column = this.tariffListService.columns;
		element.badge = {
			singular: this.i18n.translate('regulated-list.table.badge'),
			plural: this.i18n.translate('regulated-list.table.badges'),
		};
		element.sub = this.tariffListService.subColumns;

		return element;
	}

	sortTariffsByPeriod(a, b) {
		if (a.expired && !b.expired) {
			return -1;
		} else if (!a.expired && b.expired) {
			return 1;
		} else if (!a.expired && !b.expired && a.startDate && !b.startDate) {
			return -1;
		} else if (!a.expired && !b.expired && !a.startDate && b.startDate) {
			return 1;
		} else if (a.expired && b.expired && moment(a.endDate).isSameOrAfter(b.endDate)) {
			return 1;
		} else if (a.expired && b.expired && moment(a.endDate).isBefore(b.endDate)) {
			return -1;
		} else if (a.endDate && b.endDate && moment(a.endDate).isSameOrAfter(b.endDate)) {
			return 1;
		} else if (a.endDate && b.endDate && moment(a.endDate).isBefore(b.endDate)) {
			return -1;
		} else {
			return 0;
		}
	}

	onRowExpand(event, isPrivate = false) {
		if (!event || event.cycles.length) return;
		const subscription = this.tariffListService.renderSubRows(event, isPrivate).subscribe((subrows) => {
			event.cycles = subrows;
		});
		this.subscriptions.push(subscription);
	}

	doAction(event) {
		const dialogModel: GaContentDialogModel = {} as GaContentDialogModel;
		switch (event.type) {
		case 'delete':
			dialogModel.icon = 'error';
			dialogModel.iconType = 'error';
			dialogModel.title = this.i18n.translate('global.modal.delete-title');
			dialogModel.message = this.i18n.translate('global.modal.delete-message');
			dialogModel.btnError = true;
			break;
		case 'new-cycle':
			this.newCicle(event);
			return;
		default:
			this.newAction(event);
			return;
		}
		this.dialogRef = this.dialog.open(GaContentDialogComponent, {
			disableClose: true,
			data: dialogModel,
			width: '407px',
		});

		this.subscriptions.push(
			this.dialogRef.componentInstance.confirm
				.pipe(
					tap(() => {
						this.onDelete(event.row, event.id === event.row.tariff);
					})
				)
				.subscribe()
		);
	}

	onDelete(element, isTariff = false) {
		const subscription = this.tariffListService[isTariff ? 'deleteTariff' : 'deleteTariffInstance'](
			isTariff ? { id: element.tariff } : element
		)
			.pipe(
				tap(() => {
					this.notification.success({
						msg: isTariff
							? this.i18n.translate('regulated-list.delete-success')
							: this.i18n.translate('regulated-list.delete-instance-success'),
					});
					this.listTariffPrivateTable();
					this.listTariffPublicTable();
					this.closeDialog();
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					this.closeDialog();
					return error;
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	closeDialog() {
		this.dialogRef.close();
	}
}

export const ng2TariffListComponent = {
	name: 'tariffList',
	def: downgradeComponent({
		component: TariffListComponent,
		propagateDigest: true,
	}),
};
