<div class="content">
	<div class="description">
		<div class="description__texts">
			<span class="description__texts__title">{{ 'contract.label.tariffs' | transloco }}</span>
			<span class="description__texts__subtitle">{{ 'contract.label.tariffs-sub' | transloco }}</span>
		</div>

		<div class="btn-toolbar description__action-buttons" role="toolbar">
			<button
				class="btn btn-group btn-default btn-default-ga mr-4"
				type="button"
				(click)="mixPanelEvent('click_button_export_tariff'); stateGo('main.export', { tab: 'tariffs' })"
			>
				<i class="fa-regular fa-cloud-arrow-down"></i>
				{{ 'regulated-list.export' | transloco }}
			</button>
			<ga-button-dropdown
				[label]="'regulated-list.new-tariff' | transloco"
				[icon]="'fa-square-dollar'"
				*wchHasFeature="'tariffCreate'"
				[actionOptions]="actionButtons"
				(action)="newAction($event)"
			></ga-button-dropdown>
		</div>
	</div>

	<div class="row col-lg-12 m-0 p-0">
		<autocomplete-orgs [idSelected]="organizationId" (orgEmitter)="orgSelected($event)"></autocomplete-orgs>
	</div>
	<div class="row col-lg-12 m-0 p-0 dataTable">
		<div *ngIf="!organizationId" class="no-organization">
			<ga-tag-icon [icon]="'error'" [size]="22"></ga-tag-icon>
			<span class="message">
				{{ 'regulated-list.table.no-organization' | transloco }}
			</span>
		</div>
		<ga-datatable-new
			[ngClass]="{ hidden: !organizationId }"
			id="custom-tariffs-table"
			[tableName]="'CustomTariffs'"
			[tableTitle]="'regulated-list.custom-tariffs'"
			[element$]="displayElPrivate$"
			(onAction)="doAction($event)"
			(rowExpand)="onRowExpand($event, true)"
		>
			<ng-container emptyTitle>
				<h3 class="empty-table">
					{{ 'regulated-list.table.no-content' | transloco }}
				</h3>
			</ng-container>
			<ng-container emptySubtitle>
				<h4 *ngIf="isOrgAdmin" class="empty-table__tips">
					<span>
						{{ 'regulated-list.table.no-content-sub1' | transloco }}
						<a (click)="newAction(actionButtons[1])">
							{{ 'regulated-list.table.no-content-sub2' | transloco }}
						</a>
					</span>
					<span>
						{{ 'regulated-list.table.no-content-sub3' | transloco }}
					</span>
				</h4>
				<h4 *ngIf="!isOrgAdmin" class="empty-table__tips">
					<span>
						{{ 'regulated-list.table.no-content-sub4' | transloco }}
					</span>
					<span>
						{{ 'regulated-list.table.no-content-sub5' | transloco }}
					</span>
				</h4>
			</ng-container>
		</ga-datatable-new>
		<ga-datatable-new
			*ngIf="isAdmin"
			id="distributor-tariffs-table"
			[tableName]="'DistributorTariffs'"
			[tableTitle]="'regulated-list.distributor-tariffs'"
			[showSubtablePaginator]="true"
			[element$]="displayElPublic$"
			(onAction)="doAction($event)"
			(rowExpand)="onRowExpand($event, false)"
		>
			<ng-container emptyTitle>
				<h3 class="empty-table">
					{{ 'regulated-list.table.no-content' | transloco }}
				</h3>
			</ng-container>
			<ng-container emptySubtitle>
				<h4 *ngIf="isOrgAdmin" class="empty-table__tips">
					<span>
						{{ 'regulated-list.table.no-content-sub1' | transloco }}
						<a (click)="newAction(actionButtons[0])">
							{{ 'regulated-list.table.no-content-sub2' | transloco }}
						</a>
					</span>
					<span>
						{{ 'regulated-list.table.no-content-sub3' | transloco }}
					</span>
				</h4>
				<h4 *ngIf="!isOrgAdmin" class="empty-table__tips">
					<span>
						{{ 'regulated-list.table.no-content-sub4' | transloco }}
					</span>
					<span>
						{{ 'regulated-list.table.no-content-sub5' | transloco }}
					</span>
				</h4>
			</ng-container>
		</ga-datatable-new>
	</div>
</div>
