import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Observable, Subscription } from 'rxjs';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { OrganizationSmall } from 'webapp/app/shared/models/organization.model';
import { FreeTariffListService } from './free-tariff-list.service';
import { AutocompleteOrgsComponent } from 'webapp/app/shared/autocomplete-components/organizations/autocomplete-orgs.component';
import {
	GaContentDialogComponent,
	GaContentDialogModel,
} from 'webapp/app/shared/ga-dialog/ga-content-dialog.component';
import { TranslocoService } from '@ngneat/transloco';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { catchError, tap } from 'rxjs/operators';
import { ContractService } from 'webapp/app/shared/services/contract.service';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { MixPanelService } from 'webapp/app/shared/services/mixpanel.service';

@Component({
	selector: 'free-tariff-list',
	templateUrl: './free-tariff-list.component.html',
	styleUrls: ['./free-tariff-list.component.scss'],
})
export class FreeTariffListComponent implements OnDestroy {
	organizationId!: number;
	displayEl$: Observable<any> = new Observable();
	subscriptions: Subscription[] = [];
	isOrgAdmin = false;
	dialogRef!: MatDialogRef<any>;
	@ViewChild(AutocompleteOrgsComponent)
		organizationsComponent!: AutocompleteOrgsComponent;

	constructor(
		private tariffListService: FreeTariffListService,
		private i18n: TranslocoService,
		private dialog: MatDialog,
		private contractService: ContractService,
		private notification: NotificationService,
		private mixpanel: MixPanelService,
		@Inject(NG_ROUTER) private $state: any
	) {}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	orgSelected(organization: OrganizationSmall) {
		if (!organization?.id) return;
		this.organizationId = organization.id;
		this.isOrgAdmin = this.organizationsComponent.isOrgAdmin || this.organizationsComponent.currentUser.isAdmin;

		this.displayEl$ = this.tariffListService.getTariffs(this.organizationId);
	}

	onActionCliked($event: Action) {
		const type = $event.type;
		switch (type) {
		case 'edit':
			this.toEdit($event);
			break;
		case 'new-cycle':
			this.newCycle($event.id!);
			break;
		case 'delete':
			this.openDeleteDialog($event);
			break;
		case 'clone':
			this.cloneCycle($event);
			break;
		}
	}

	toEdit($event: Action) {
		if ($event.row) {
			this.$state.transitionTo('main.cycle-details', {
				contractId: $event.row.tariff,
				cycleId: $event.id,
			});
		} else {
			this.$state.transitionTo('main.contracts-details', { id: $event.id });
		}
	}

	newCycle(id: number) {
		this.$state.transitionTo('main.cycle-new', { contractId: id });
	}

	openDeleteDialog(event: Action) {
		const dialogModel: GaContentDialogModel = {} as GaContentDialogModel;
		dialogModel.icon = 'error';
		dialogModel.iconType = 'error';
		dialogModel.title = this.i18n.translate('contract.modal-delete.title');
		dialogModel.message = this.i18n.translate('contract.modal-delete.message');
		dialogModel.btnError = true;

		this.dialogRef = this.dialog.open(GaContentDialogComponent, {
			disableClose: true,
			data: dialogModel,
			width: '407px',
		});

		const subscription = this.dialogRef.componentInstance.confirm
			.pipe(
				tap(() => {
					if (event.row) {
						this.deleteInstance(event.row);
					} else {
						this.deleteTariff(event.id);
					}
				})
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	deleteInstance(instance) {
		const subscription = this.contractService
			.__deleteTariffInstance(instance)
			.pipe(
				tap(() => {
					this.notification.success({
						msg: this.i18n.translate('contract.notify.delete.instance-success'),
					});
					this.displayEl$ = this.tariffListService.getTariffs(this.organizationId);
					this.closeDialog();
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					this.closeDialog();
					return error;
				})
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	deleteTariff(id) {
		const subscription = this.contractService
			.__deleteTariff(id)
			.pipe(
				tap(() => {
					this.notification.success({
						msg: this.i18n.translate('contract.notify.delete.tariff-success'),
					});
					this.displayEl$ = this.tariffListService.getTariffs(this.organizationId);
					this.closeDialog();
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					this.closeDialog();
					return error;
				})
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	closeDialog() {
		this.dialogRef.close();
	}

	cloneCycle($event: Action) {
		this.$state.transitionTo('main.cycle-duplicate', {
			contractId: $event.row.tariff,
			duplicateId: $event.id,
		});
	}

	onRowExpand(event) {
		if (!event || event.cycles.length) return;

		const subscription = this.tariffListService.renderCycles(event.id).subscribe((cycles) => {
			event.cycles = cycles;
		});

		this.subscriptions.push(subscription);
	}

	mixPanelEvent(type) {
		this.mixpanel.mixPanelEvent({ type, object: {} });
	}

	toNew() {
		this.mixPanelEvent('click_button_contract_create');
		this.$state.transitionTo('main.contracts-new');
	}
}

export const ng2FreeTariffListComponent = {
	name: 'freeTariffList',
	def: downgradeComponent({
		component: FreeTariffListComponent,
		propagateDigest: true,
	}),
};
