<div class="content">
	<div class="description">
		<div class="description__texts">
			<span class="description__texts__title">{{ 'contract.label.contracts' | transloco }}</span>
			<span class="description__texts__subtitle">{{ 'contract.label.contracts-sub' | transloco }}</span>
		</div>

		<div class="btn-toolbar description__action-buttons" role="toolbar">
			<button class="btn btn-color-ga" type="button" (click)="toNew()">
				<i class="fa-regular fa-file-plus"></i>
				{{ 'contract.btn.add' | transloco }}
			</button>
		</div>
	</div>

	<div class="col-lg-12 m-0 p-0">
		<autocomplete-orgs [idSelected]="organizationId" (orgEmitter)="orgSelected($event)"> </autocomplete-orgs>
	</div>

	<div class="row col-lg-12 m-0 p-0 dataTable">
		<div *ngIf="!organizationId" class="no-organization">
			<ga-tag-icon [icon]="'error'" [size]="22"></ga-tag-icon>
			<span class="message">
				{{ 'contract.table.no-organization' | transloco }}
			</span>
		</div>
		<ga-datatable-new
			[ngClass]="{ hidden: !organizationId }"
			[tableName]="'Contracts'"
			[element$]="displayEl$"
			(onAction)="onActionCliked($event)"
			(rowExpand)="onRowExpand($event)"
		>
			<ng-container emptyTitle>
				<h3 class="empty-table">
					{{ 'contract.table.empty-table' | transloco }}
				</h3>
			</ng-container>
			<ng-container emptySubtitle>
				<h4 *ngIf="isOrgAdmin" class="empty-table__tips">
					<span>
						{{ 'contract.table.empty-table-msg-1' | transloco }}
						<a (click)="toNew()">{{ 'contract.table.empty-table-msg-2' | transloco }}</a></span
					>
					<span> {{ 'contract.table.empty-table-msg-3' | transloco }}</span>
				</h4>
				<h4 *ngIf="!isOrgAdmin" class="empty-table__tips">
					<span>
						{{ 'contract.table.not-admin-msg' | transloco }}
					</span>
				</h4>
			</ng-container>
		</ga-datatable-new>
	</div>
</div>
