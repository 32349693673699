<div class="content">
	<div class="description">
		<div class="description__texts">
			<span class="description__texts__title">{{ 'main.menu.tags' | transloco }}</span>
			<span class="description__texts__subtitle">{{ 'tags.tags-sub' | transloco }}</span>
		</div>

		<div class="btn-toolbar description__action-buttons" role="toolbar" *ngIf="organizationId">
			<button class="btn btn-color-ga" type="button" (click)="createTag()" *wchHasFeature="'tagsCreate'">
				<i class="fa-regular fa-tag"></i>
				{{ 'tags.new' | transloco }}
			</button>
		</div>
	</div>

	<div class="row col-lg-12 m-0 p-0">
		<autocomplete-orgs [idSelected]="organizationId" (orgEmitter)="orgSelected($event)"></autocomplete-orgs>
	</div>

	<div class="row col-lg-12 m-0 p-0 dataTable">
		<div *ngIf="!organizationId" class="no-organization">
			<ga-tag-icon [icon]="'error'" [size]="22"></ga-tag-icon>
			<span class="message">
				{{ 'tags.table.no-organization' | transloco }}
			</span>
		</div>
		<ga-datatable-new
			[ngClass]="{ hidden: !organizationId }"
			[tableName]="'tags'"
			[element$]="displayEl$"
			(onAction)="onActionCliked($event)"
		>
			<ng-container emptyTitle>
				<h3 class="empty-table">
					{{ 'tags.table.empty-table' | transloco }}
				</h3>
			</ng-container>
			<ng-container emptySubtitle>
				<h4 class="empty-table__tips">
					<span>
						{{ 'tags.table.empty-table-msg-1' | transloco }}
						<a (click)="createTag()">{{ 'tags.table.empty-table-msg-2' | transloco }}</a></span
					>
					<span> {{ 'tags.table.empty-table-msg-3' | transloco }}</span>
				</h4>
			</ng-container>
		</ga-datatable-new>
	</div>
</div>
