import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { StateParams, StateService } from 'angular-ui-router';
import { EMPTY, Observable, Subscription, from } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { DataTableList } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { NG_ROUTER, NG_STATE_PARAMS } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { UserListService } from './user-list.service';
import { UserService } from 'webapp/app/shared/services/user.service';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { WINDOW } from '@ng-web-apis/common';
import {
	GaContentDialogComponent,
	GaContentDialogModel,
} from 'webapp/app/shared/ga-dialog/ga-content-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SendDataSourceNotification } from '../user-notification/user-notification.model';
import { addOrUpdateItem } from 'webapp/app/utils/arrUtil';
import { MixPanelService } from 'webapp/app/shared/services/mixpanel.service';

@Component({
	selector: 'user-list',
	templateUrl: './user-list.component.html',
	styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit, OnDestroy {
	@ViewChild('notificationTemplate') notificationTemplate!: TemplateRef<any>;
	organizationId: number | undefined = undefined;
	displayEl$: Observable<DataTableList> = new Observable();
	subscriptions: Subscription[] = [];
	dialogRef!: MatDialogRef<any>;
	currentUser = {} as any;
	isAdmin = false;
	currentUserId!: number;
	changedUserDSNotify: SendDataSourceNotification[] = [];

	constructor(
		@Inject(NG_ROUTER) private $state: StateService,
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		@Inject(WINDOW) private window: Window,
		@Inject(TranslocoService) private i18n,
		private notification: NotificationService,
		private userListService: UserListService,
		private userService: UserService,
		public dialog: MatDialog,
		private mixpanel: MixPanelService
	) {
		if (this.$stateParams?.organizationId) this.organizationId = this.$stateParams.organizationId;
	}

	ngOnInit(): void {
		this.loadCurrentUser();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	mixPanelEvent(type) {
		this.mixpanel.mixPanelEvent({ type, object: {} });
	}

	loadCurrentUser() {
		this.subscriptions.push(
			from(this.userService.getCurrentUser())
				.pipe(
					tap((user) => {
						this.currentUser = user;
						this.isAdmin = user.isAdmin;
					})
				)
				.subscribe()
		);
	}

	notificationsDialog(element: any) {
		const dialogModel: GaContentDialogModel = {} as GaContentDialogModel;
		dialogModel.template = this.notificationTemplate;
		dialogModel.icon = 'notification';
		dialogModel.title = `${this.i18n.translate('user.notification.dropdown')} ${element.firstName}`;

		this.dialogRef = this.dialog.open(GaContentDialogComponent, {
			disableClose: true,
			data: dialogModel,
			width: '390px',
			height: '507px',
		});

		this.confirmUserNotifyButton();
		this.closeUserNotifyButton();
	}

	onActionCliked(event: Action) {
		this.currentUserId = event.row.id;
		switch (event.type) {
		case 'edit':
			this.stateGo('main.user-invite', { id: event.row.id });
			return;
		case 'active':
			this.activate(event.row);
			break;
		case 'inactive':
			this.deactivate(event.row);
			break;
		case 'notification':
			this.notificationsDialog(event.row);
			return;
		case 'personificate':
			this.personificate(event.row);
			return;
		case 'delete':
			this.openDeleteDialog(event.row);
			break;
		default:
			break;
		}
	}

	activate(user) {
		user.active = true;
		const data = {
			user,
			success: 'user.activate-success',
			error: 'user.activate-error',
		};
		this.saveUser(data);
	}

	deactivate(user) {
		user.active = false;
		const data = {
			user,
			success: 'user.deactivate-success',
			error: 'user.deactivate-error',
		};
		this.saveUser(data);
	}

	openDeleteDialog(user) {
		const dialogModel: GaContentDialogModel = {} as GaContentDialogModel;
		dialogModel.icon = 'error';
		dialogModel.iconType = 'error';
		dialogModel.title = this.i18n.translate('user-invite.modal.delete-title');
		dialogModel.message = this.i18n.translate('user-invite.modal.delete-message');
		dialogModel.btnError = true;

		this.dialogRef = this.dialog.open(GaContentDialogComponent, {
			disableClose: true,
			data: dialogModel,
			width: '407px',
		});

		const subscription = this.dialogRef.componentInstance.confirm.pipe(tap(() => this.deleteUser(user))).subscribe();
		this.subscriptions.push(subscription);
	}

	deleteUser(user) {
		user.active = false;
		user.deleted = true;
		const data = {
			user,
			success: 'user.delete-success',
			error: 'user.delete-error',
		};
		this.saveUser(data);
	}

	closeDialog() {
		this.dialogRef.close();
	}

	setModalLoading(event) {
		this.dialogRef.componentInstance.loading = event;
	}

	saveUser(data) {
		const subscription = from(this.userService.saveUser(data.user.id, { user: data.user }))
			.pipe(
				tap(() => {
					this.notification.success({ msg: this.i18n.translate(data.success) });
					if (this.dialogRef) this.closeDialog();
					this.loadUsers();
				}),
				catchError(() => {
					this.notification.error({ msg: this.i18n.translate(data.error) });
					return EMPTY;
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	personificate(user) {
		const subscription = from(this.userService.personificate({ ...user, keepCookies: true }))
			.pipe(tap(() => this.window.location.reload()))
			.subscribe();
		this.subscriptions.push(subscription);
	}

	loadUsers() {
		const params = this.organizationId ? { organizationId: this.organizationId } : {};

		this.displayEl$ = this.userListService.listUsers(params).pipe(
			map((data) => {
				const element: DataTableList = {} as DataTableList;
				element.data = this.userListService.renderListToTable(data, this.isAdmin);
				element.column = this.userListService.columns;
				element.badge = {
					singular: this.i18n.translate('user.label.person'),
					plural: this.i18n.translate('user.label.people'),
				};
				element.filter = this.userListService.filterTable;
				return element;
			})
		);
	}

	orgSelected(event: { id: number; name: string }) {
		if (!event?.id || event?.id === this.organizationId) return;
		this.organizationId = event?.id;

		this.loadUsers();
	}

	stateGo(route, params) {
		this.$state.transitionTo(route, params);
	}

	getDataSources(event) {
		if (!event || !event.length) return;

		event.forEach((user) => {
			if (user.dataSources.length) return;
			user.dataSources = this.userListService.renderDataSourcesPoints(user.id);
		});
	}

	changeUserDSNotify(event: SendDataSourceNotification): void {
		this.changedUserDSNotify = addOrUpdateItem(this.changedUserDSNotify, event, 'dataSourceId');
	}

	closeUserNotifyButton() {
		const subscription = this.dialogRef.componentInstance.close.subscribe(() => {
			this.changedUserDSNotify = [];
		});
		this.subscriptions.push(subscription);
	}

	confirmUserNotifyButton() {
		const subscription = this.dialogRef.componentInstance.confirm.subscribe(() => {
			this.closeDialog();
			if (!this.changedUserDSNotify.length) {
				return;
			}
			const params = {
				dataSource: JSON.stringify(this.changedUserDSNotify.map((ds) => ds.dataSourceId)),
			};

			const subscription = this.userService
				.updateListOfUserMeter(this.changedUserDSNotify, this.currentUser.id, params)
				.subscribe(
					() => {
						this.notification.success({
							msg: this.i18n.translate('user.notification.toggle-enabled'),
						});
						this.changedUserDSNotify = [];
					},
					(err) => {
						console.log(err);
						this.notification.error({
							msg: this.i18n.translate('user.notification.toggle-error'),
						});
					}
				);
			this.subscriptions.push(subscription);
		});
		this.subscriptions.push(subscription);
	}
}

export const ng2UserListComponent = {
	name: 'userList',
	def: downgradeComponent({
		component: UserListComponent,
		propagateDigest: true,
	}),
};
