<label *ngIf="title">
	<span>{{ title }}</span>
	<span *ngIf="required" class="required">*</span>
	<ga-helper
		*ngIf="tooltip"
		[text]="tooltip"
		[icon]="'fa-solid fa-circle-info'"
		[iconSize]="12"
		[iconColor]="'var(--grayscale---gray-400)'"
		[tooltipLocale]="'above'"
	></ga-helper>
</label>
<div [id]="'timepicker-' + id" class="input-icons" [matMenuTriggerFor]="menu" [class.disabled]="!canOpen || disabled">
	<i class="fa-regular fa-clock icon"></i>
	<input
		type="text"
		class="form-control final-time"
		[ngClass]="{ 'has-error': showError || (rangeError && isRange) }"
		[placeholder]="placeholder"
		[formControl]="ctrlTime"
	/>
</div>
<mat-menu #menu="matMenu" class="menu dropdown-menu-right">
	<ul class="time-containt" [attr.style]="'width: ' + panelWidth + 'px;'" (click)="$event.stopPropagation()">
		<ng-container *ngIf="!isRange">
			<ga-input-time [time]="time" (changes)="onTimeChanges($event)" [haveSeconds]="haveSeconds"></ga-input-time>
		</ng-container>
		<ng-container *ngIf="isRange">
			<ga-input-time
				[time]="timeRange.start"
				(changes)="onRangeChanges($event, 'initial')"
				[haveSeconds]="haveSeconds"
			></ga-input-time>
			<span style="padding: 4px">{{ 'global.at' | transloco }}</span>
			<ga-input-time
				[time]="timeRange.end"
				(changes)="onRangeChanges($event, 'final')"
				[haveSeconds]="haveSeconds"
			></ga-input-time>
		</ng-container>
	</ul>
	<hr />
	<div class="group">
		<button type="button" class="btn btn-default" (click)="clear()">
			{{ 'global.cancel' | transloco }}
		</button>
		<button type="button" class="btn btn-primary btn-color-ga" (click)="apply()">
			{{ 'global.apply' | transloco }}
		</button>
	</div>
</mat-menu>

<mat-error *ngIf="showError && !(rangeError && isRange)">{{
	error || ('global.field-required' | transloco)
}}</mat-error>

<mat-error *ngIf="rangeError && isRange">
	{{ 'global.end-date-before' | transloco }}
</mat-error>
