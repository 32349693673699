import { HttpClient } from '@angular/common/http';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { CookieService } from 'ngx-cookie-service';

import { ng2ApplianceService } from 'webapp/app/shared/services/appliance.service';
import { ng2BranchService } from 'webapp/app/shared/services/branch.service';
import { ng2CategoryService } from 'webapp/app/shared/services/category.service';
import { ng2CCEEService } from 'webapp/app/shared/services/ccee.service';
import { ng2ClassificationService } from 'webapp/app/shared/services/classification.service';
import { ng2CompositionService } from 'webapp/app/shared/services/composition.service';
import { ng2ConsumptionService } from 'webapp/app/shared/services/consumption.service';
import { ng2CredentialsService } from 'webapp/app/shared/services/credentials.service';
import { ng2KpiService } from 'webapp/app/shared/services/kpi.service';
import { ng2RoleService } from 'webapp/app/shared/services/role.service';
import { ng2TariffService } from 'webapp/app/shared/services/tariff.service';
import { ng2TaskService } from 'webapp/app/shared/services/task.service';
import { DistributorService } from 'webapp/app/shared/services/distributor.service';
import { ng2AlertService } from 'webapp/app/dashboard/alerts/alert.service';
import { ng2CompanyService } from 'webapp/app/shared/services/company.service';
import { ng2OrganizationService } from 'webapp/app/shared/services/organization.service';
import { WhiteLabelService } from 'webapp/app/shared/app-shell/whitelabel.service';
import { ng2CacheService } from 'webapp/app/shared/services/cache.service';

//angular.module('dashboard-services').service(ng2TagService.name, ng2OrganizationService.def);
angular
	.module('dashboard-services')
	.service(ng2CacheService.name, ng2CacheService.def);
angular
	.module('dashboard-services')
	.service(ng2OrganizationService.name, ng2OrganizationService.def);
angular
	.module('dashboard-services')
	.service(ng2TaskService.name, ng2TaskService.def);
angular
	.module('dashboard-services')
	.service(ng2TariffService.name, ng2TariffService.def);
angular
	.module('dashboard-services')
	.service(ng2RoleService.name, ng2RoleService.def);
angular
	.module('dashboard-services')
	.service(ng2KpiService.name, ng2KpiService.def);
angular
	.module('dashboard-services')
	.service(ng2CredentialsService.name, ng2CredentialsService.def);
angular
	.module('dashboard-services')
	.service(ng2ConsumptionService.name, ng2ConsumptionService.def);
angular
	.module('dashboard-services')
	.service(ng2CompositionService.name, ng2CompositionService.def);
angular
	.module('dashboard-services')
	.service(ng2ClassificationService.name, ng2ClassificationService.def);
angular
	.module('dashboard-services')
	.service(ng2CCEEService.name, ng2CCEEService.def);
angular
	.module('dashboard-services')
	.service(ng2CategoryService.name, ng2CategoryService.def);
angular
	.module('dashboard-services')
	.service(ng2BranchService.name, ng2BranchService.def);
angular
	.module('dashboard-services')
	.service(ng2ApplianceService.name, ng2ApplianceService.def);
angular
	.module('dashboard-services')
	.service(ng2AlertService.name, ng2AlertService.def);
angular
	.module('dashboard-services')
	.service(ng2CompanyService.name, ng2CompanyService.def);
angular
	.module('dashboard-services')
	.service(DistributorService.name, downgradeInjectable(DistributorService));
angular
	.module('dashboard-services')
	.service(WhiteLabelService.name, downgradeInjectable(WhiteLabelService));
angular
	.module('dashboard-services')
	.service(HttpClient.name, downgradeInjectable(HttpClient));
angular
	.module('dashboard-services')
	.service(CookieService.name, downgradeInjectable(CookieService.name));
