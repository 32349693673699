<div class="content">
	<div class="description">
		<div class="texts">
			<span class="title">{{ 'global.branch.users' | transloco }}</span>
			<span class="subtitle">{{ 'global.branch.users-def' | transloco }}</span>
		</div>

		<div class="btn-toolbar action-buttons" role="toolbar" *wchHasFeature="'userCreate'">
			<button
				class="btn btn-color-ga new-btn"
				type="button"
				(click)="mixPanelEvent('click_button_users_create'); stateGo('main.user-invite', { id: null, organizationId })"
			>
				<i class="fa-regular fa-user"></i>
				{{ 'user.invite' | transloco }}
			</button>
		</div>
	</div>

	<div class="row col-lg-12 m-0 p-0">
		<autocomplete-orgs [idSelected]="organizationId" (orgEmitter)="orgSelected($event)"></autocomplete-orgs>
	</div>

	<div class="row col-lg-12 m-0 p-0 dataTable">
		<div *ngIf="!organizationId" class="no-organization">
			<ga-tag-icon [icon]="'error'" [size]="22"></ga-tag-icon>
			<span class="message">
				{{ 'user.no-organization' | transloco }}
			</span>
		</div>
		<ga-datatable-new
			[ngClass]="{ hidden: !organizationId }"
			[tableName]="'users'"
			[element$]="displayEl$"
			(onAction)="onActionCliked($event)"
			(renderDataChange)="getDataSources($event)"
		>
			<ng-container emptyTitle>
				<h3 class="empty-table">
					{{ 'user.no-content' | transloco }}
				</h3>
			</ng-container>
			<ng-container emptySubtitle>
				<h4 class="empty-table__tips">
					<span>
						{{ 'user.no-content-sub1' | transloco }}
						<a (click)="stateGo('main.user-invite', { id: null, organizationId })">
							{{ 'user.no-content-sub2' | transloco }}</a
						>
					</span>
					<span>{{ 'user.no-content-sub3' | transloco }}</span>
				</h4>
			</ng-container>
		</ga-datatable-new>
	</div>
</div>
<ng-template #notificationTemplate>
	<user-notification
		[userId]="currentUserId"
		(emitChange)="changeUserDSNotify($event)"
		(loading)="setModalLoading($event)"
	></user-notification>
</ng-template>
