import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { environment } from 'webapp/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class CacheService {
	private currentVersion = localStorage.getItem('appVersion') || '';

	private getMetaVersion(): string | null {
		const metaTag = document.querySelector('meta[name="app-version"]');
		const tagAttribute = metaTag?.getAttribute('content') || null;
		return tagAttribute;
	}

	public checkVersion() {
		if (!environment.production) return;

		const newVersion = this.getMetaVersion();
		if (newVersion && newVersion !== this.currentVersion) {
			this.updateServiceWorker();
			this.updateVersion(newVersion);
		}
	}

	private updateVersion(newVersion: string) {
		localStorage.setItem('appVersion', newVersion);
		console.log('Nova versão detectada:', newVersion);

		caches.keys().then((keys) => {
			Promise.all(keys.map(async (key) => caches.delete(key)))
				.then(() => {
					location.reload();
				})
				.catch((err) => {
					console.error('Erro ao limpar caches:', err);
				});
		});
	}

	private updateServiceWorker() {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.getRegistrations().then((registrations) => {
				for (const registration of registrations) {
					registration.update();
				}
			});
		}
	}
}

export const ng2CacheService = {
	name: CacheService.name,
	def: downgradeInjectable(CacheService),
};
