import { HttpClientModule } from '@angular/common/http';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule, setAngularJSGlobal } from '@angular/upgrade/static';
import * as angular from 'angular';
import NgDashboardModule from '@repo/projects/ng-app/src/main';
import {
	auxiliarProvider,
	ngCacheFactoryProvider,
	ngHttpProvider,
	ngQProvider,
	ngRootScopeProvider,
	ngRouterParamsProvider,
	ngRouterProvider,
	ngTransitionProvider,
	notifyProvider,
	sharedSourceProvider,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { AppShellModule } from './shared/app-shell/app-shell.module';
import { ChartConfigService } from './shared/chart/chart-config.service';
import { ComponentContainerModule } from './shared/component-container/component-container.module';
import { TenantModule } from './shared/tenant/tenant.module';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { CookieService } from 'ngx-cookie-service';
import { SAILS_SOCKET, socketFactory } from './setup/sails';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConfirmDialogModule } from './shared/confirm-dialog/confirmation-dialog.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import localePt from '@angular/common/locales/pt';
import { DatePipe, registerLocaleData } from '@angular/common';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { TawkService } from './shared/services/tawk.service';
import { NgxColorsModule } from 'ngx-colors';
import { MaterialModule } from '../../global/material/material.module';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CacheService } from './shared/services/cache.service';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

registerLocaleData(localePt, 'pt-BR');
setAngularJSGlobal(angular);

export function versionCheckFactory(cacheService: CacheService) {
	return () => cacheService.checkVersion();
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		UpgradeModule,
		AuthModule.forRoot(),
		HttpClientModule,
		HttpCacheInterceptorModule.forRoot(),
		TranslocoRootModule,
		ComponentContainerModule,
		DashboardModule,
		AppShellModule,
		TenantModule,
		BrowserAnimationsModule,
		NgxColorsModule,
		ConfirmDialogModule,
		MaterialModule,
		NgxMaskModule.forRoot(options),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			registrationStrategy: 'registerWhenStable:30000',
		}),
	],
	providers: [
		ngRouterProvider,
		ngHttpProvider,
		ngQProvider,
		ngCacheFactoryProvider,
		auxiliarProvider,
		ngRootScopeProvider,
		ChartConfigService,
		CookieService,
		TawkService,
		MatDatepickerModule,
		ngRouterParamsProvider,
		ngTransitionProvider,
		sharedSourceProvider,
		notifyProvider,
		{ provide: SAILS_SOCKET, useFactory: socketFactory },
		{ provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
		{ provide: LOCALE_ID, useValue: 'pt-BR' },
		{
			provide: APP_INITIALIZER,
			useFactory: versionCheckFactory,
			deps: [CacheService],
			multi: true,
		},
		DatePipe,
	],

	//bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private upgrade: UpgradeModule) {}

	ngDoBootstrap() {
		this.upgrade.bootstrap(
			document.body,
			[NgDashboardModule]
			//{ strictDi: true }
		);
	}
}
