import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { ColumnType } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { environment } from 'webapp/environments/environment';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ProinfaListService {
	tariffTypes: any[] = [];
	constructor(private http: HttpClient, private i18n: TranslocoService) {}

	getTariffs(params: any = {}): Observable<any> {
		return this.http.get<any>(`${environment.backendUrl}/api/tariffs/${params.id || ''}`, {
			params,
		});
	}

	getTariffInstances(tariff): Observable<any> {
		return this.http.get<any>(
			`${environment.backendUrl}/api/tariffs/${tariff.id}/instances/${tariff.instanceId || ''}`
		);
	}

	deleteTariff(tariff: any = {}): Observable<any> {
		return this.http.delete<any>(`${environment.backendUrl}/api/tariffs/` + tariff.id);
	}

	deleteTariffInstance(tariffInstance: any = {}): Observable<any> {
		return this.http.delete<any>(
			`${environment.backendUrl}/api/tariffs/` + tariffInstance.tariff + '/instances/' + tariffInstance.id
		);
	}

	createTariff(body): Observable<any> {
		return this.http.post<any>(`${environment.backendUrl}/api/tariffs`, body);
	}

	saveTariff(body): Observable<any> {
		return this.http.put<any>(`${environment.backendUrl}/api/tariffs/${body.id}`, body);
	}

	createTariffInstance(body): Observable<any> {
		return this.http.post<any>(`${environment.backendUrl}/api/tariffs/${body.tariff}/instances`, body);
	}

	saveTariffInstance(body): Observable<any> {
		return this.http.put<any>(`${environment.backendUrl}/api/tariffs/${body.tariff}/instances/${body.id}`, body);
	}

	renderListToTable(tariffs) {
		return tariffs.map((tariff) => {
			const row = {} as any;
			row.id = tariff.id;
			row.name = tariff.name;
			row.cycles = [];
			row.period = this.renderPeriod(tariff);
			row.action = this.getActions(tariff);
			return row;
		});
	}

	renderSubRows(proinfa: any) {
		return this.getTariffInstances(proinfa).pipe(
			map((response) => response.tariffInstances),
			map((tariffs) =>
				tariffs.map((tariff: any, index: number) => {
					const subrow = {} as any;
					subrow.id = tariff.id;
					subrow.name = `${this.i18n.translate('contract.label.cicle')} ${tariffs.length - index}`;
					subrow.period = this.renderPeriod(tariff);
					subrow.action = this.getSubrowActions(tariff);
					return subrow;
				})
			)
		);
	}

	renderPeriod(tariff) {
		if (!tariff.startDate || !tariff.endDate) {
			return '';
		}
		const startDate = moment(tariff.startDate).format('DD/MM/YYYY');
		const endDate = moment(tariff.endDate).format('DD/MM/YYYY');
		const today = moment().format();
		const secondLine = this.i18n.translate('contract.tariff.expired-text');

		if (moment(today).isBefore(tariff.endDate)) {
			return `
				<span class="flex-col">
				${startDate} - ${endDate}
				</span>
			`;
		} else {
			return `
				<span class="flex-col expired">
				${startDate} - ${endDate}
				<div>${secondLine}</div>
				</span>
			`;
		}
	}

	get columns(): ColumnType[] {
		return [
			{
				label: this.i18n.translate('proinfa-list.col-name'),
				name: 'name',
				type: 'text',
				isExpanded: true,
				expandedName: 'cycles',
				width: '60%',
			},
			{
				label: this.i18n.translate('proinfa-list.col-period'),
				name: 'period',
				type: 'custom',
				width: '40%',
			},
			{
				label: '',
				name: 'action',
				type: 'action',
				width: '45px',
			},
		];
	}

	get subColumns(): ColumnType[] {
		return [
			{
				label: this.i18n.translate('proinfa-list.col-name'),
				name: 'name',
				type: 'text',
				width: '60%',
			},
			{
				label: this.i18n.translate('proinfa-list.col-period'),
				name: 'period',
				type: 'custom',
				width: '40%',
			},
			{
				label: '',
				name: 'action',
				type: 'action',
				width: '45px',
			},
		];
	}

	getActions(proinfa): Action[] {
		const contentRow = {
			tariff: proinfa.id,
			id: proinfa.tariffInstanceId,
			startDate: proinfa.startDate,
			endDate: proinfa.endDate,
		};
		const actions: Action[] = [
			{
				icon: 'fa-edit',
				title: this.i18n.translate('global.edit'),
				type: 'edit',
				permission: 'tariffUpdate',
				row: contentRow,
			},
			{
				icon: 'fa-calendar-plus',
				title: this.i18n.translate('contract.table.new-cycle'),
				type: 'new-cycle',
				row: contentRow,
			},
			{
				icon: 'fa-trash',
				title: this.i18n.translate('global.delete'),
				type: 'delete',
				permission: 'tariffDelete',
				row: contentRow,
			},
		];
		return actions;
	}

	getSubrowActions(tariffInstance): Action[] {
		const actions: Action[] = [
			{
				icon: 'fa-edit',
				title: this.i18n.translate('global.edit'),
				type: 'edit',
				permission: 'tariffUpdate',
				row: tariffInstance,
			},
			{
				title: this.i18n.translate('contract.table.duplicate'),
				icon: 'fa-clone',
				type: 'clone',
				row: tariffInstance,
			},
			{
				icon: 'fa-trash',
				title: this.i18n.translate('global.delete'),
				type: 'delete',
				permission: 'tariffDelete',
				row: tariffInstance,
			},
		];
		return actions;
	}
}
