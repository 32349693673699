<div class="content">
	<div class="description">
		<div class="description__texts">
			<span class="description__texts__title">{{ 'main.menu.organizations' | transloco }}</span>
			<span class="description__texts__subtitle">{{ 'organization.label.desc' | transloco }}</span>
		</div>

		<div class="btn-toolbar description__action-buttons" role="toolbar">
			<button class="btn btn-color-ga" type="button" (click)="toNew()">
				<i class="fa-regular fa-sitemap"></i>
				{{ 'organization.btn.add-new' | transloco }}
			</button>
		</div>
	</div>

	<div class="row col-lg-12 m-0 p-0">
		<ga-datatable-new
			[tableName]="'organization'"
			[element$]="displayTable$"
			(onAction)="onActionCliked($event)"
			(renderDataChange)="getLazyColumns($event)"
			[showElements]="false"
		></ga-datatable-new>
	</div>
</div>
